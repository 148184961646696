// Here you can add other styles
@font-face {
  font-family: 'InterBold';
  src: url(../assets/fonts/Inter-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'InterSemiBold';
  src: url(../assets/fonts/Inter-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'InterRegular';
  src: url(../assets/fonts/Inter-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'InterMedium';
  src: url(../assets/fonts/Inter-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'InterLight';
  src: url(../assets/fonts/Inter-Light.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansRegular';
  src: url(../assets/fonts/NotoSans-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansBold';
  src: url(../assets/fonts/NotoSans-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'TypoRound';
  src: url(../assets/fonts/Typo_Round_Regular_Demo.otf);
  font-display: swap;
}

body {
  font-family: 'Manrope', 'sans-serif';
  background-color: #f0f0f0;

  &::-webkit-scrollbar {
    width: 9px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    transition: 0.2s ease-in-out;
    visibility: hidden;
    background-color: #21AF7D;
    border-radius: 8px;
    width: 2px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      // opacity: 1;
    }
  }
}

.cr-pointer {
  cursor: pointer;
}

.align-items-center {
  align-items: center;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

input[type='checkbox'] {
  visibility: hidden;
}

input[type='checkbox']+label:before {
  border: 2px solid #ffa850;
  content: '\00a0';
  display: inline-block;
  font: 20px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  margin-top: 3px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  cursor: pointer;
}

input[type='checkbox']:checked+label:before {
  border: 2px solid #ffa850;
  border-image: url('../assets/icons/border.svg') 70;
  background-color: none;
  border-image-repeat: space;
  border-image-slice: 10;
  border-image-width: 90px;
  height: 17px;
  color: #ffa850;
  content: '\2713';
  font-size: 15px;
  padding-left: 2px;
  font-weight: 700;
  text-align: center;

}

input[type='checkbox']:checked+label:after {
  font-weight: bold;
}

input[type='checkbox']:focus+label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.label-checkbox {
  position: relative;
  padding: 0;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
  cursor: pointer;
}

.textarea-not-float,
.textarea-not-float:focus {
  border: none;
  border-bottom: 1px solid #979797;
  width: 50%;
  outline: none;
}

#new-user-button {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 5px 10px;
  color: #ffffff;
  cursor: pointer;
}

.status-active {
  color: #5f9d2e !important;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
}

.status-not-active {
  color: #d33232 !important;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
}

.warning-toaster {
  border-radius: 1.9px;
  background-color: #fffbe7;
  padding: 14px;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: #f07a39;
}

.pagination li a {
  padding: 0 10px;
  color: #2f2f2f;
  cursor: pointer;
}

.pagination li.active a {
  color: #20a8d8;
}

.pagination .disabled a {
  color: grey;
}

.pagination .disabled a:hover {
  opacity: 1;
}

.pagination li a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.background-image {
  max-height: 400px;
  height: 50vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.card-styles-1 {
  border-radius: 25px;
  background-color: #ffffff;
  padding: 20px 0;
  color: #6c6d71;
}

.dividing__line {
  border: 2px solid #f7f7f7;
}

// .card {
//   border-radius: 25px;
//   box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
// }

.logo-image {
  z-index: 1;
  width: 50px;
  height: auto;
}

.logo-image-landing {
  position: absolute;
  z-index: 1;
  width: 100px;
  top: 120px;
  left: calc(50% - 50px);
}

.sign-in-landing {
  position: absolute;
  z-index: 2;
  right: 50px;
  top: 30px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.33px;
  color: #fbb12f;
  cursor: pointer;
}

.logo-register {
  position: absolute;
  z-index: 1;
  width: 100px;
}

.form-position {
  margin-top: 30px;
}

.form-with-logo {
  margin-top: 100px;
}

.landing-button {
  z-index: 1;
  display: flex;
}

.form-title {
  font-family: 'NotoSansBold';
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 10px;
  color: #231f20;
  padding: 20px 0;
}

.field-group {
  position: relative;

  input {
    padding: 20px 34px 6px;
    border: none;
    border-bottom: 1px solid #979797;
    outline: 0;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: #2f2f2f;
    border-radius: 0;
  }

  label {
    display: inline-block;
    position: absolute;
    padding: 17px 34px;
    transition: all 150ms ease-in;
    color: #231f20;
    font-size: 12px;
  }
}

.input-icon {
  position: absolute;
  left: 0;
  top: 18px;
  width: 16px;
  height: 16px;
}

/* when the label is activated, it jumps up (using translateY) */
/* you could add animation here as well */
label.field-active {
  transform: translateY(-14px);
  font-size: 11px;
  color: #000;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff,
    0 -2px 0 #fff;
}

.floating-label {
  -webkit-appearance: none !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

input {
  padding: 20px 34px 6px;
  border: none;
  border-bottom: 1px solid #979797;
  outline: 0;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #2f2f2f;
}

.input-not-float {
  padding: 0;
  width: 50%;
}

.input-not-float::placeholder {
  color: rgba(108, 109, 113, 0.6);
}

.input-not-float:disabled {
  opacity: 0.7;
  color: #5c6873;
}

.form-label {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #6c6d71;
  flex: 0 0 22%;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
}

#button-link-change-password {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 10px 20px;
  color: #ffffff;
  text-transform: uppercase;
}

.custom-button {
  border-radius: 17.8px;
  background-color: #fbb12f;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 270px;
}

.custom-button:disabled {
  opacity: 0.7;
}

.MuiCircularProgress-colorPrimary.white {
  color: #ffffff !important;
}

.card-description-1 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
  font-size: 14px;
}

.card-form {
  border-radius: 25px;
  box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
  background-color: #ffffff;
  z-index: 1;
}

.card-landing {
  padding: 30px 90px;
  margin-top: 30px;
  width: 100%;
  margin-bottom: 70px;
  max-width: 800px;
}

.card-header-landing {
  font-size: 35px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.84px;
  color: #6c6d71;
}

.card-description {
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
}

.card-step {
  border-radius: 27.5px;
  border: solid 2px rgba(190, 190, 190, 0.5);
  padding: 20px 10px;
  min-height: 236px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6c6d71;
}

.card-logo {
  margin-bottom: 60px;
}

.card-registration-button {
  text-align: center;
  padding: 12px 0;
  background-color: #fbb12f;
  color: #ffffff;
  border-radius: 15px;
  margin-top: 30px;
}

footer {
  text-align: center;
  padding: 30px 0;
  background-color: rgba(35, 31, 32, 0.97);
  color: #f7f7f7;
  width: 100%;
}

footer li {
  margin-right: 15px;
}

footer ul {
  display: inline-flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding-left: 0;
}

li {
  list-style: none;
}

footer .copyright {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
}

footer .logo-image-footer {
  width: 32px;
  margin-left: 16px;
}

.line {
  border: 1px solid #231f20;
  margin-bottom: 30px;
}

.line-gray {
  border: 1px solid #f7f7f7;
}

.line-2 {
  border: solid 1.4px #231f20;
  margin-bottom: 10px;
  width: 100%;
}

.field-form {
  padding: 0 30px;
}

#forgot-password {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.btn-submit {
  border-radius: 25px;
  background-color: #fbb12f;
  width: 100%;
  font-family: 'NotoSansBold';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 8px 0;
  cursor: pointer;
}

.btn-submit:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

// .react-datepicker__input-container input {
//   padding: 8px 4px;
//   border: 1px solid #979797;
//   border-radius: 5px;
//   text-align: center;
//   cursor: pointer;
// }

.sort__options {
  display: none;
}

.sort__options.show {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  width: 240px;
  padding: 20px;
  right: 66px;
  border-radius: 14px;
  box-shadow: -4px 5px 9px 0 rgba(0, 0, 0, 0.5);
  transition: 0.2s ease-in-out;
  z-index: 1;
}

.sort__option {
  cursor: pointer;
  width: max-content;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  margin-bottom: 8px;
}

.sort__option.active {
  color: #fbb12f;
}

.mobile-datepicker {
  .react-datepicker-wrapper {
    width: 100%;

    .react-datepicker__input-container input {
      width: 100%;
    }
  }
}

.button-search {
  border-radius: 8px !important;
  background: #ffde17 !important;
  outline: none !important;

  .button-text {
    font-size: 14px;
  }

  &:hover {
    background: #ffde17;
  }
}

table {
  min-width: 100%;
}

table tr th:first-child,
table tr td:first-child {
  width: 20px !important;
}

.table th,
.table td {
  vertical-align: middle;
  padding: 0 !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.table td {
  border-top: none !important;
}

.table thead td {
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: #6c6d71;
}

.table tbody td {
  font-family: 'NotoSansRegular';
  font-size: 13px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: #6c6d71;
  width: 100px;
  height: auto;
}

td.status-pending,
div.status-pending {
  color: #f8c045 !important;
  font-weight: 600;
}

td.status-success,
div.status-success {
  color: #5f9d2e !important;
  font-weight: 600;
}

td.status-fail,
div.status-fail {
  color: #d33232 !important;
  font-weight: 600;
}

td.status-request,
div.status-request {
  color: #2971ff !important;
  font-weight: 600;
}

td.status-deleted,
div.status-deleted {
  color: #6c6d71 !important;
  font-weight: 600;
}

.show-limit-title {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.show-limit {
  padding: 0 8px;
  border-right: 1px solid #979797;
  cursor: pointer;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.show-limit.active {
  color: #e46161;
}

.show-limit:first-child() {
  padding-left: 0;
}

.show-limit:last-child() {
  border-right: none;
}

.justify-content-space-between {
  justify-content: space-between;
}

.font-bold {
  font-weight: bold;
  color: #191a1f;
}

.table-display {
  color: #231f20;
  font-size: 16px;

  span {
    color: #f23b3b;
  }
}

.paginate-button {
  background-image: url('../assets/img/pagination-button.svg');
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  border: none;
}

.align-items-center {
  align-items: center;
}

.paginate-button:disabled {
  opacity: 0.5;
}

.paginate-button:first-child {
  transform: rotate(90deg);
}

.paginate-button:last-child {
  transform: rotate(-90deg);
}

.export-file {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 6px 14px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  border: none;
}

.search-button {
  border-radius: 8.4px;
  background-color: #ffde17;
  height: 30px;
  width: 30px;
  border: none;
}

.search__input {
  font-size: 12px !important;
  padding: 9px 8px !important;
  border: 1px solid #979797 !important;
  border-radius: 4px;
}

.search__select {
  font-size: 12px !important;
  padding: 7px !important;
  border: 1px solid #979797 !important;
  border-radius: 4px !important;
}

.form__label {
  font-family: 'NotoSansRegular';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #6c6d71;
}

.clear__filter {
  cursor: pointer;
  width: max-content;
}

.MuiFormLabel-root.form__label {
  font-size: 1rem !important;
  font-family: 'NotoSansRegular' !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: -0.39px !important;
  color: #6c6d71 !important;
}

.flex__filter {
  display: flex;
  align-items: flex-end;

  .react-datepicker__input-container input {
    padding: 0px 4px;
    border: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    text-align: left;
  }

  .search-selects>.MuiSelect-select {
    font-family: 'NotoSansRegular';
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 4px 0 2px 0;
    font-weight: 600;
    color: #000;
    opacity: 0.8;
  }
}

.MuiPaginationItem-root {
  color: #231F20 !important;

  &.Mui-selected {
    border-radius: 4px !important;
    background-color: #110E0F !important;
    color: #FBB12F !important;

    &:hover {
      outline: none;
      border: none;
      background-color: rgba(251, 177, 47, 0.2) !important;
    }

    &:active,
    &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  &:hover {
    outline: none;
    border: none;
    background-color: #fff !important;
  }

  &:active,
  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.pages {
  // display: block;
  padding: 20px 30px;
  width: calc(100vw - 290px);
  transition: width 0.4s ease-out;

  &.active {
    width: calc(100vw - 110px);
    transition: width 0.4s ease-out;
  }

  .main {
    padding-bottom: 40px;

    .page-container {
      // width: 100%;

      &.active {
        padding: 0 20px 0 40px;
      }

      .page-title {
        font-size: 35px;
        margin-bottom: 24px;
        font-family: 'InterBold', sans-serif;
      }

      .dashboard-body {

        .banner-container {
          background-color: #FEEBBE;
          border: 1px solid #FDBE2C;
          border-radius: 4px;
          padding: 1rem 0.5rem;
          margin: 0 5px 1rem 5px;

          h4,
          span {
            font-family: Manrope;
            font-size: 16px;
            font-weight: 700;

            span {
              color: #FDBE2C;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }

        .card-Dasboard {
          display: flex;
          // justify-content: space-evenly;

          .body-card {
            background-image: url(../assets/img/group-19.svg);
            background-size: 65%;
            background-repeat: no-repeat;
            background-position-x: 135px;
            background-position-y: 30px;
            display: flex;
            margin-top: 0;
            margin: 5px;
            padding: 12px;
            margin-bottom: 24px;
            border-radius: 4px;
            background-color: #fff;

            .icon {
              width: 54px;
              height: 54px;
              margin: 0 16px 1px 0;
              padding: 15px;
              object-fit: contain;
              border-radius: 4px;
              background-image: linear-gradient(137deg, #fdd95c -12%, #fbb12f 108%);
            }

            .title-t {
              font-family: 'InterRegular', sans-serif;
              font-size: 14px;
              font-weight: bold;
              margin: 0;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #231f20;
            }

            p {
              margin: 10px 16px 0 1px;
              text-shadow: 0 2px 4px rgba(0, 0, 0, 0.06);
              font-family: 'InterBold', sans-serif;
              font-size: 23px;
              // font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #231f20;
            }
          }
        }

        .Graphic-Body {
          padding: 20px;
          border-radius: 4px;
          box-shadow: 0 2px 22px 2px rgba(0, 0, 0, 0.1);
          background-color: #fff;
          margin-bottom: 20px;

          .Graphic__Header {

            // display: flex;
            h4 {
              font-family: 'InterBold', sans-serif;
              font-size: 21px;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #231f20;
            }

            p {
              font-family: 'InterSemiBold';
              font-size: 14px;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.71;
              letter-spacing: normal;
              color: #231f20;
            }

            .title__tr {
              margin-left: 5px;
              font-family: 'InterBold', sans-serif;
              font-size: 21px;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #231f20;
            }

            .period__button {
              display: flex;

              .button {
                cursor: pointer;
                margin-right: 20px;
                width: 110px;
                padding: 12px;
                text-align: center;
                border-radius: 4px;
                background-color: #f7b500;
                font-family: 'InterBold', sans-serif;
                font-size: 14px;
                font-weight: bold;
                color: #231f20;

              }

              .button:hover {
                background-color: #231f20;
                color: white;
              }
            }
          }

          .Graphic__ {
            display: flex;
            // background-color: #191a1f;
            width: 100%;
            align-items: center;

            // margin-top: 20px;
            // width: 100% !important;
            .Graphic__line2 {
              // background-color: #f07a39;
              width: 55% !important;
              height: 80% !important;
            }

            .Graphic__Doughnut {
              width: 50% !important;
              // height: 320px !important;
              justify-content: center;
              align-items: center;
              display: flex;
              padding: 30px;
              margin-left: 40px;
            }
          }
        }
      }

      .nav {
        background-color: #fff;
        border-radius: 4px;
        border: none;
        display: flex;
        align-items: center;

        .nav-link {
          border: none;
          border-radius: 4px;
          padding: 14px 20px;
          font-family: 'InterBold', sans-serif;
          transition: all 0.2s ease-in-out;
          background-color: #fff;
          color: #969696 !important;

          &.active {
            border: none;
            border-radius: 4px;
            padding: 14px 20px;
            font-family: 'InterBold', sans-serif;
            transition: all 0.2s ease-in-out;
            background-color: #f7b500 !important;
            position: relative;
            color: #231f20 !important;

            &::before {
              content: '';
              height: 2px;
              width: 65%;
              background-color: #241f20;
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              margin-left: auto;
              margin-right: auto;
            }
          }
        }
      }

      .tab-content {
        border: none;
        background-color: transparent;
        padding: none;

        .page-content {
          margin-bottom: 20px;

          h2 {
            font-size: 18px;
            font-weight: bold;
            font-family: 'InterBold', sans-serif;
          }

          .button-group {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow: auto;

            button {
              font-weight: 15px;
              color: #969696;
              padding: 8px 14px;
              background-color: white;
              border-radius: 4px;
              border: solid 1px #e4e6e8;
              transition: all 0.4s ease-in-out;
              white-space: nowrap;

              &:focus {
                outline: none !important;
              }

              &.active {
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
                border: solid 1px #f7b500 !important;
                color: #231f20;
              }
            }
          }

          .submit-button {
            border: solid 1px #e4e6e8 !important;
            background-color: #969696;
            padding: 8px 30px;
            color: white;
            border-radius: 4px;

            &:focus {
              outline: none !important;
            }

            &.submit-active {
              background-color: #110e0f;
              color: #fbb12f;
            }
          }
        }
      }
    }

    .page-drawer {
      width: 100%;
      min-height: 100vh;
      height: 100%;
      position: absolute;
      z-index: 88;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.6);
      transition: 0.5s ease-in-out;
      visibility: hidden;
      display: none;

      &.active {
        display: flex;
        visibility: visible;
      }

      .drawer-content {
        background-color: white;
        min-width: 40vw;
        padding: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container-mobile {
    padding: 0 27px;
  }

  .sort__options.show {
    position: relative;
    right: 0;
    width: 100%;
  }

  .card-logo {
    margin-bottom: 30px;
  }

  .logo-register {
    position: absolute;
    z-index: 1;
    width: 50px;
    left: calc(50% - 16px);
    top: 50px;
  }

  .image-container {
    position: absolute;
    width: 100%;
  }

  .form-register {
    border-radius: 25px;
    box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
    background-color: #ffffff;
    position: relative;
    top: 150px;
    margin-bottom: 30px;
  }

  .background-image-landing-page {
    height: 100vh;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  .step-landing {
    z-index: 1;
    padding: 0 20px;
  }

  .sign-up-now {
    font-size: 14px;
  }

  .card-landing {
    padding: 30px 30px;
  }

  .card-step {
    height: 250px;
    margin-bottom: 20px;
  }

  footer .logo-image-footer {
    display: none;
  }

  .sign-in-landing {
    right: 20px;
  }
}

.search-title {
  color: #6c6d71 !important;
}

.search-group {
  max-width: 75%;

  .search-title {
    letter-spacing: 0.4px;
    color: #231f20;
    font-size: 16px;
  }

  .search-form {
    .MuiInputBase-input {
      font-family: 'NotoSansRegular';
      font-size: 12px;
      letter-spacing: 0.4px;
      padding: 4px 0 2px 0;
      font-weight: 600;
      color: #000;
      opacity: 0.8;
    }

    // input::placeholder {
    //   color: rgb(78, 78, 78) !im;
    // }

    .MuiInput-underline {
      &::after {
        border-bottom-color: #231f20;
      }

      &.Mui-error::after {
        border-bottom-color: #f44336;
      }
    }

    .MuiInput-input::placeholder {
      letter-spacing: 0.4px;
      font-weight: 400;
    }
  }
}

// Card Layout Component

.card-layout {
  padding: 25px 0;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 4px;
  // max-width: 100%;
  // width: fit-content;
  // max-width: calc(100vw - 350px);

  &__title {
    font-size: 18px !important;
    font-family: 'InterBold', sans-serif;
  }
}

.tab-content {
  border: none;
  background-color: transparent;
  padding: none;

  .tab-pane>.card-layout {
    margin: 0 -1rem;
  }
}

@media only screen and (max-width: 768px) {
  .search-group {
    max-width: 100%;
  }

  .nav {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 450px) {
  .drawer-content {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .pages {
    width: calc(100vw - 10px);
    padding: 20px;

    &.active {
      width: calc(100vw - 10px);
    }
  }
}

@media only screen and (min-width: 1025px) {
  .background-image-landing-page {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .step-landing {
    z-index: 1;
    padding: 0 180px;
  }

  .card-step {
    margin-bottom: 20px;
  }

  .card-description {
    font-size: 10px;
  }
}

.react-datepicker__input-container {
  input {
    opacity: 0.4;
    border-radius: 4px;
    border: solid 1px #231f20;
    padding: 17px 18px;

    &::placeholder {
      font-size: 14px;
      color: #231f20;
      text-align: left;
    }
  }
}

.pointer {
  cursor: pointer !important;
}


.css-zow5z4-MuiGrid-root {
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 100% !important;
}

.css-mhc70k-MuiGrid-root {
  margin-left: 0 !important;
  margin-top: 0 !important;
  width: 100% !important;
}

.c-btn-submit {
  font-family: Manrope !important;
  padding: 8px 16px;
  outline: none !important;
  border: none !important;
  background-color: #231f20;
  color: #ffa850;
  border-radius: 4px;
  transition: 0.2s background-color ease-in-out;
  font-size: 14px !important;
  font-weight: 500;
  display: inline-flex;
  align-items: center;

  &.gray {
    background-color: #969696;
    color: white;
  }

  &:not(:disabled) {

    &:focus,
    &:hover {
      outline: none !important;
      background-color: #231f20d7;
    }
  }
}

.tabel-title {
  color: #241f20;
  font-size: 16px;
  margin-bottom: 15px;
}

table {

  .thead-list {
    background-color: #fef4e2;

    &.gray {
      background-color: #E8E8E8 !important;
      text-transform: none !important;
    }

    th {
      font-family: Manrope, sans-serif !important;
      border: none !important;
      color: #231F20;
      white-space: nowrap;
      font-weight: 700;
    }
  }

  tbody.tbody-list {
    td {
      font-family: 'InterRegular', sans-serif !important;
      font-size: 14px;
      color: #231F20;
      line-height: normal;
    }

    .report-status {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      p {
        width: auto;
        margin: auto, 0;
        border-radius: 4px;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .status_success {
        color: #8992a6;
        background-color: #d2ede4;

      }

      .status_failed {
        background-color: #fcdada;
        color: #f04545;
      }

      .status_pending {
        background-color: #ffeedc;
        color: #ffa850;
      }

    }

    .no-data {
      // height: 60px;
      padding: 40px 0 20px !important;
    }
  }
}

.invalid-status {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  border-radius: 4px;
  background-color: #fcdada;
  color: #f04545;
  text-align: center;
  display: flex;
  justify-content: center;
  width: fit-content;
}

.pending-status {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #ffeedc;
  color: #ffa850;
  text-align: center;
  display: flex;
  justify-content: center;
}

.valid-status {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #d2ede4;
  color: #8992a6;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionCreatedStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #feefd3;
  color: #f49d00;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionProcessedStatus {
  font-family: 'InterRegular', sans-serif !important;
  font-size: 8px;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #ffddbf;
  color: #ff7900;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionWaitingorApproved {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #ffddbf;
  color: #ff7900;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionCancelledStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #f6cbc0;
  color: #dc2f02;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionRejectedStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #f6cccc;
  color: #d00000;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionPausedStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #cceefa;
  color: #00a8e8;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionDoneStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #daecdd;
  color: #40914c;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionExpiredStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #dee0e5;
  color: #7d8598;
  text-align: center;
  display: flex;
  justify-content: center;
}

.transactionDeletedStatus {
  font-family: 'InterRegular', sans-serif !important;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 4px;
  background-color: #dee0e5;
  color: #7d8598;
  text-align: center;
  display: flex;
  justify-content: center;
}

.customize {
  font-family: 'InterRegular', sans-serif !important;

  &.secret {
    .MuiInputBase-root {
      input {
        text-overflow: ellipsis;

        &:disabled {
          color: #231f20 !important;
        }
      }
    }
  }

  .Mui-focused {
    color: #231f20 !important;

    fieldset {
      border-color: #231f20 !important;
    }
  }
}

.submit-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-bottom: 10px;

  p {
    text-align: center;
    margin-top: 14px;
  }

  .button-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: none;
      outline: none;
      background-color: transparent;
      font-weight: bold;
      text-align: center;
      flex-grow: 1;
    }
  }
}

.hr {
  height: 12px;
  border-top: none;
  margin: 27px 0 30px;
  background-color: #f0f0f0
}


.customDatePicker-wrapper {
  .datepicker {
    width: 100%;
    border: none;
    color: black !important;
  }
}

.react-datepicker__input-container input {
  border: none;
}

.datepicker {
  border: solid black 1px;
  width: 100%;
  color: black !important;

}