html body .app.flex-row.align-items-center {
  min-height: 100vh;
}

// ie11 floating footer temp fix, react only
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #root {
    display: flex;
    flex-direction: column;
  }
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
 
/* Track */
::-webkit-scrollbar-track {
  background: #d8d8d896;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    
  background: #6f676796;
}
::-webkit-scrollbar-thumb:window-inactive {
	background: none;
}